const initialState = {
  titles: [],
  loading: false,
  error: ''
};


export const chatHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case 'CHAT_HISTORY_REQUEST':
      return { loading: true };
    case 'CHAT_HISTORY_SUCCESS':
      return { loading: false, history: action.payload, historyReset: false };
    case 'CHAT_HISTORY_FAIL':
      return { loading: false, error: action.payload };
    case 'CHAT_HISTORY_RESET':
      return {};
    default:
      return state;
  }
};

export const newChatReducer = (state = {}, action) => {
  switch (action.type) {
    case 'NEW_CHAT_REQUEST':
      return { loading: true };
    case 'NEW_CHAT_SUCCESS':
      return { loading: false, isClicked: true };
    case 'NEW_CHAT_FAIL':
      return { loading: false, error: action.payload };
    case 'NEW_CHAT_RESET':
      return {};
    default:
      return state;
  }
};

export const chatTitlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CHAT_TITLES_REQUEST':
      return {
        ...state,
        loading: state?.titles?.length === 0, // Only set loading true if no titles exist
        error: ''
      };
    case 'GET_CHAT_TITLES_SUCCESS':
      const newTitles = action.payload?.filter(
        (newTitle) =>
          newTitle &&
          !state?.titles?.some((existingTitle) => existingTitle?.chat_id === newTitle?.chat_id)
      ) || [];

      return {
        ...state,
        titles: [...newTitles ,...(state.titles || []) ], // Ensure state.titles is always an array
        loading: false,
         error: ''
      };
    case 'EDIT_CHAT_TITLE':
      return {
        ...state,
        successEdit: true,
        titles: state.titles?.map((chat) =>
          chat.chat_id === action.payload.chatId
            ? { ...chat, chat_title: action.payload.newTitle }
            : chat
        ),
      };
    case 'DELETE_CHAT_TITLE':
      return {
        ...state,
        titles: state.titles?.filter(
          (chat) => chat.chat_id !== action.payload.chatId
        ),
      };
    case 'TOGGLE_PIN_CHAT':
      return {
        ...state,
        titles: state.titles?.map((chat) =>
          chat.chat_id === action.payload.chatId
            ? { ...chat, is_pinned: action.payload.isPinned }
            : chat
        ),
      };
    case 'GET_CHAT_TITLES_FAIL':
      return { loading: false, error: action.payload, ...state };
    case 'SUCCESS_EDIT_RESET':
      return { ...state, successEdit: false };
    case 'GET_CHAT_TITLES_RESET':
      return {};
    default:
      return state;
  }
};

export const checkServerReducer = (state = { isServerUp: true }, action) => {
  switch (action.type) {
    case 'SET_SERVER_STATUS':
      return { isServerUp: action.payload };
    default:
      return state;
  }
};
