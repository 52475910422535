import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Avatar } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GavelIcon from '@mui/icons-material/Gavel';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';

const HomeMainPage = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleCardClick = (route) => {
    if (route === '/app/case-management') return;
    if (route === '/app/legal-doc') return;
    if (route === '/app/legal-library') return;
    navigate(route);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        flex: 5,
        p: '2px 8px 0px 8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '5%',
        }}
      >
        <Box mb={2}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#4285F4',
              display: 'inline-block',
            }}
          >
            Hello,
          </Typography>
          <Typography
            variant="h4"
            component="span"
            sx={{
              fontWeight: 'bold',
              background: 'linear-gradient(90deg, #6a88e2, #a56dd2, #ec6f78)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline-block',
              marginLeft: '8px',
            }}
          >
            {userInfo?.name}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              color: '#b0b0b0',
              fontWeight: 400,
              marginTop: '4px',
            }}
          >
            Welcome to LawGPT, how can I help you today?
          </Typography>
        </Box>
        <Grid container spacing={2} width="720px">
          {[
            {
              label: 'Legal Chat',
              text: 'AI-powered legal assistance for precise responses to legal inquiries',
              icon: (
                <Avatar
                  sx={{
                    bgcolor: '#e3f2fd',
                  }}
                >
                  <ChatIcon sx={{ color: '#76d0eb' }} />
                </Avatar>
              ),
              route: '/app/legal-chat',
            },
            {
              label: 'Case Law Search',
              text: 'Effortlessly access key case laws to instantly retrieve relevant cases',
              icon: (
                <Avatar
                  sx={{
                    bgcolor: '#efebe9',
                  }}
                >
                  <GavelIcon sx={{ color: '#a56d51' }} />
                </Avatar>
              ),
              route: '/app/searchResults',
            },
            {
              label: 'Statute Search',
              text: 'Quickly retrieve pertinent statutes to locate applicable laws seamlessly',
              icon: (
                <Avatar
                  sx={{
                    bgcolor: '#fff8e1',
                  }}
                >
                  <MenuBookOutlinedIcon sx={{ color: '#e2c541' }} />
                </Avatar>
              ),
              route: '/app/statute-search',
            },
            {
              label: 'Bookmarks',
              text: 'Mark and save important resources for easy access to key legal references',
              icon: (
                <Avatar
                  sx={{
                    bgcolor: '#e8eaf6',
                  }}
                >
                  <TurnedInIcon sx={{ color: '#3C50B1' }} />
                </Avatar>
              ),
              route: '/app/statute-search',
            },
            {
              label: 'Application Drafting',
              text: 'Structured legal application creation to generate well-organized applications',
              icon: (
                <Avatar
                  sx={{
                    bgcolor: '#f3e5f5',
                  }}
                >
                  <FontAwesomeIcon icon={faFileSignature} color="#cb8bd0" />
                </Avatar>
              ),
              route: '/app/app-management',
            },
            {
              label: 'Petition Drafting',
              text: 'Professional petition drafting to create accurate, high-quality legal documents',
              icon: (
                <Avatar
                  sx={{
                    bgcolor: '#e0f2f1',
                  }}
                >
                  <AssignmentIcon sx={{ color: '#00d67f' }} />
                </Avatar>
              ),
              route: '/app/petition',
            },

            {
              label: 'Legal Library',
              text: 'A comprehensive collection of legal resources to access an extensive legal repository',
              icon: (
                <Avatar
                  sx={{
                    bgcolor: '#ffebee',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBook}
                    color="#ff6347"
                    fontSize="20px"
                  />
                </Avatar>
              ),
              route: '/app/legal-library',
            },
            {
              label: 'Document Analysis',
              text: 'AI-enhanced document analysis to extract insights from complex legal texts',
              icon: (
                <Avatar
                  sx={{
                    bgcolor: '#bbdefb',
                  }}
                >
                  <QueryStatsIcon sx={{ color: '#00bfd6' }} />
                </Avatar>
              ),
              route: '/app/document-analysis',
            },
          ].map((item, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleCardClick(item.route)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor:
                      item.label === 'Case Management' ? '#e0e0e0' : '#fff',
                    borderRadius: '12px',
                    width: '100%',
                    height: 180,
                    padding: '16px',
                    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
                    backdropFilter: 'blur(15px)',
                    transition:
                      item.label === 'Case Management'
                        ? 'none'
                        : 'transform 0.3s ease, box-shadow 0.3s ease',
                    cursor:
                      item.label === 'Case Management'
                        ? 'not-allowed'
                        : 'pointer',
                    '&:hover':
                      item.label === 'Case Management'
                        ? {}
                        : {
                            transform: 'translateY(-10px)',
                            boxShadow: '0 12px 32px rgba(0, 0, 0, 0.3)',
                          },
                    '&:active':
                      item.label === 'Case Management'
                        ? {}
                        : {
                            transform: 'translateY(0)',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          },
                    '& img': {
                      marginBottom: '12px',
                    },
                    position: 'relative',
                  }}
                >
                  {item.icon}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color: '#36454f',
                        fontSize: '13px',
                        mb: 1,
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '11px',
                        color: 'gray',
                        maxWidth: '90%',
                      }}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default HomeMainPage;
