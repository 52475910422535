import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Tooltip,
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ChatIcon from '@mui/icons-material/Chat';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import GavelIcon from '@mui/icons-material/Gavel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';

const HomeSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState('Home');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    switch (item) {
      case 'Home':
        navigate('/home');
        break;
      case 'Legal Chat':
        navigate('/app/legal-chat');
        break;
      case 'Case Law Search':
        navigate('/app/searchResults');
        break;
      case 'Statute Search':
        navigate('/app/statute-search');
        break;
      case 'Petition Drafting':
        navigate('/app/petition');
        break;
      case 'Application Drafting':
        navigate('/app/app-management');
        break;
      case 'Bookmarks':
        navigate('/app/bookmarks');
        break;
      case 'Legal Library':
        navigate('/app/legal-library');
        break;
      default:
        navigate('/app/document-analysis');
        break;
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/home':
        setSelectedItem('Home');
        break;
      case '/app/legal-chat':
        setSelectedItem('Legal Chat');
        break;
      case '/app/searchResults':
        setSelectedItem('Case Law Search');
        break;
      case '/app/statute-search':
        setSelectedItem('Statute Search');
        break;
      case '/app/petition':
        setSelectedItem('Petition Drafting');
        break;
      case '/app/bookmarks':
        setSelectedItem('Bookmarks');
        break;
      case '/app/document-analysis':
        setSelectedItem('Document Analysis');
        break;
      case '/app/legal-library':
        setSelectedItem('Legal Library');
        break;
      default:
        break;
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === '/home') {
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  }, [location]);

  const menuItems = [
    { label: 'Home', icon: <OtherHousesIcon fontSize="small" /> },
    { label: 'Legal Chat', icon: <ChatIcon fontSize="small" /> },
    { label: 'Case Law Search', icon: <GavelIcon fontSize="small" /> },
    {
      label: 'Statute Search',
      icon: <MenuBookOutlinedIcon fontSize="small" />,
    },
    { label: 'Bookmarks', icon: <BookmarkIcon fontSize="small" /> },
    {
      label: 'Application Drafting',
      icon: <FontAwesomeIcon icon={faFileSignature} fontSize="18px" />,
    },
    { label: 'Petition Drafting', icon: <AssignmentIcon fontSize="small" /> },
    {
      label: 'Legal Library',
      icon: <FontAwesomeIcon icon={faBook} fontSize="18px" />,
    },
    { label: 'Document Analysis', icon: <QueryStatsIcon fontSize="small" /> },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 50px)',
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          flex: 1,
          width: isSidebarOpen ? '278px' : '50px',
          transition: 'width 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
          p: '8px',
          backgroundColor: '#fff',
          overflow: 'hidden',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <List sx={{ flexGrow: 1 }}>
          {menuItems.map((item) => (
            <ListItem key={item.label} disablePadding>
              <Tooltip
                title={!isSidebarOpen ? item.label : ''}
                placement="right"
                disableInteractive
                arrow
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: '#101936',
                    },
                    '& .MuiTooltip-arrow': {
                      color: '#101936',
                    },
                  },
                }}
              >
                <ListItemButton
                  onClick={() => handleItemClick(item.label)}
                  sx={{
                    minHeight: 38,
                    justifyContent: isSidebarOpen ? 'initial' : 'center',
                    px: 2,
                    color:
                      selectedItem === item.label ? 'primary.main' : '#36454f',
                    backgroundColor:
                      selectedItem === item.label ? '#F5F5F5' : 'transparent',
                    borderRadius: '8px',
                    position: 'relative',
                    '&:hover': {
                      backgroundColor: '#e6f2fb',
                      boxShadow: '0 1px 4px rgba(60, 80, 179, 0.25)',
                    },
                    marginBottom: 1,
                    transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isSidebarOpen ? 3 : 'auto',
                      justifyContent: 'center',
                      color: '#3c50b1',
                      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                      fontSize: '20px',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{
                      m: 0,
                      opacity: isSidebarOpen ? 1 : 0,
                      transform: isSidebarOpen
                        ? 'translateX(0)'
                        : 'translateX(-10px)',
                      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                      position: 'absolute',
                      left: '52px',
                      pointerEvents: isSidebarOpen ? 'auto' : 'none',
                      width: '180px',
                    }}
                    primaryTypographyProps={{
                      color:
                        selectedItem === item.label ? '#3c50b1' : '#36454f',
                      fontSize: 14,
                      fontWeight:
                        selectedItem === item.label ? 'bold' : 'medium',
                      whiteSpace: 'nowrap',
                      sx: {
                        transition: 'color 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                      },
                    }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isSidebarOpen ? 'flex-end' : 'center',
          }}
        >
          <IconButton
            sx={{
              color: '#3c50b1',
              transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            }}
            onClick={toggleSidebar}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeSidebar;
