import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import InsightsIcon from '@mui/icons-material/Insights';
import Markdown from 'react-markdown';
import config from './../../config/api';
import { Stack } from '@mui/system';

const CaseDetailRightSidebar = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const messageContainerRef = useRef(null);

  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  // const caseDetailsFromCosmos = useSelector(
  //   (state) => state.caseDetailsFromCosmos
  // );
  // const { caseItem: additionalCaseDetails } = caseDetailsFromCosmos;

  // API Call for chatbot response
  const generateResponse = async (userMessage) => {
    const endpoint = `${config.apiUrl}/base_chat`;

    const requestBody = {
      temperature: 'precise',
      answer_length: 'high',
      query: userMessage,
      context: caseItem?.Case_Description,
    };
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    return response.json();
  };

  // Function to handle sending messages
  const handleSendMessage = async () => {
    if (userInput.trim() === '') return;

    const userMessage = {
      sender: 'User',
      text: userInput,
    };

    setMessages((prevState) => [...prevState, userMessage]);
    setUserInput('');
    setIsLoading(true);

    const chatbotResponse = await generateResponse(userInput);

    const botResponse = {
      sender: 'Bot',
      text: chatbotResponse.response,
    };

    setMessages((prevMessages) => [...prevMessages, botResponse]);
    setIsLoading(false);
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Box
      sx={{
        p: 2,
        boxSizing: 'border-box',
        display: 'flex',
        width: 316,
        height: '100%',
        overflow: 'hidden',
        flexDirection: 'column',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          paddingBottom: '4px',
          borderBottom: '1px solid #e6e6e6',
        }}
      >
        <InsightsIcon sx={{ color: '#4CAF50' }} />
        <Typography
          sx={{
            fontWeight: 'bold',
            color: '#303f9f',
            fontSize: '18px',
            //paddingBottom: '4px',
          }}
        >
          Find Case Insights
        </Typography>
      </Stack>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          ref={messageContainerRef}
          sx={{
            borderRadius: '8px',
            backgroundColor: '#fff',
            padding: 2,
            flex: 1,
            overflowY: 'auto',
            border: '1px solid grey',
            mt: 2,
            mb: 2,
            '::-webkit-scrollbar': {
              width: '5px',
            },
            '::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#101936',
              borderRadius: '6px',
            },
          }}
        >
          {messages.length === 0 ? (
            <Typography
              variant="body1"
              sx={{ color: '#757575', textAlign: 'center', marginTop: 2 }}
            >
              Ask your case related queries here!
            </Typography>
          ) : (
            <>
              {messages.map((message, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent:
                      message.sender === 'User' ? 'flex-end' : 'flex-start',
                    mb: 1,
                  }}
                >
                  <Typography
                    align="left"
                    sx={{
                      backgroundColor:
                        message.sender === 'User' ? '#f5f5f5' : '#fff',
                      //color: message.sender === 'User' ? '#black' : '#000000',
                      borderRadius: '8px',
                      padding: '8px 12px',
                      display: 'inline-block',
                      wordWrap: 'break-word',
                    }}
                  >
                    <Markdown>{message.text}</Markdown>
                  </Typography>
                </Box>
              ))}

              {/* Loading Indicator for Bot */}
              {isLoading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    mb: 1,
                  }}
                >
                  <Typography
                    align="left"
                    sx={{
                      //backgroundColor: '#e0e0e0',
                      color: '#000000',
                      borderRadius: '8px',
                      padding: '8px 12px',
                      display: 'inline-block',
                      wordWrap: 'break-word',
                    }}
                  >
                    <span className="dot-flashing"></span>
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
        {/* Chat Input Field */}
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            bgcolor: 'white',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Type your query here..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleEnter}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                fontSize: '12px',
              },
            }}
          />
          <IconButton
            size="small"
            sx={{
              backgroundColor: '#303f9f',
              '&:hover': {
                backgroundColor: '#303f9f',
              },
            }}
            onClick={handleSendMessage}
          >
            <SendIcon fontSize="small" sx={{ color: '#fff' }} />
          </IconButton>
        </Box>
      </Box>
      <style>
        {`
.dot-flashing {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: #000;
  animation: dot-flashing 1s infinite ease-in-out both;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
}

.dot-flashing::before {
  left: -12px;
  animation: dot-flashing 1s infinite ease-in-out 0.2s both;
}

.dot-flashing::after {
  left: 12px;
  animation: dot-flashing 1s infinite ease-in-out 0.4s both;
}

@keyframes dot-flashing {
  0% {
    opacity: 0.2;
  }
  50%,
  100% {
    opacity: 1;
  }
}
        `}
      </style>
    </Box>
  );
};

export default CaseDetailRightSidebar;
