const config = {
  backendUrl: 'https://rg-law-gpt-be-auth2.azurewebsites.net/api',
  apiUrl: 'https://rg-law-gpt-openai-functions.azurewebsites.net/api',
  // petitionUrl: 'https://rg-law-gpt-ml-petition-functions.azurewebsites.net/api',
  petitionUrl:
    'https://rg-lawgpt-legal-document-generation.azurewebsites.net/api',
  petitionForm:
    'https://rg-lawgpt-legal-document-generation.azurewebsites.net/api',
  statuteSearchUrl: 'https://search-engine-lawgpt.azurewebsites.net/api/search',
  searchUrl:
    'https://search-engine-caselaws-test-pld.azurewebsites.net/api/search',
  testSearchUrl:
    'https://search-engine-caselaws-test-pld.azurewebsites.net/api/search',
  baseURL: 'https://dev.platform.lawgpt.pk',
};
//https://search-engine-lawgpt.azurewebsites.net
////'https://search-engine-caselaws-test-pld.azurewebsites.net/api/search',

export default config;
