import React, { useState } from 'react';
import { Dialog, Stack } from '@mui/material';
import CaseSummarySidebar from './layout/CaseSummarySidebar';
import CaseSummary from './CaseSummary';

const CaseSummaryDialog = ({ open, onClose }) => {
  const [selectedSummarySection, setSelectedSummarySection] =
    useState('Case Summary');

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
        <CaseSummarySidebar
          onSectionClick={setSelectedSummarySection}
          selectedSection={selectedSummarySection}
          closeOnBack={onClose}
        />
        <CaseSummary selectedSection={selectedSummarySection} />
      </Stack>
    </Dialog>
  );
};

export default CaseSummaryDialog;
