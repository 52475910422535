import React from 'react';
import { Dialog, Stack } from '@mui/material';
import CaseDetail from './CaseDetail';
import CaseDetailRightSidebar from './layout/CaseDetailRightSidebar';

const CaseDetailDialog = ({ open, onClose }) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Stack sx={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
        <CaseDetail closeOnBack={onClose} />
        <CaseDetailRightSidebar />
      </Stack>
    </Dialog>
  );
};

export default CaseDetailDialog;
