import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import config from '../config/api';
import backgroundImage from './../assets/images/bgImage.png';

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const { token } = useParams();
  const navigate = useNavigate();

  const isStrongPassword = (password) => {
    return password.length >= 8;
  };

  const resetPassword = async (email, newPassword) => {
    try {
      const response = await fetch(`${config.backendUrl}/resetPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, newPassword }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to reset password');
      }

      const data = await response.json();
      return { success: true, message: data.message };
    } catch (error) {
      console.error('Error:', error.message);
      return { success: false, message: error.message };
    }
  };

  const handleReset = async () => {
    setPasswordError('');
    setConfirmPasswordError('');

    if (!isStrongPassword(newPassword)) {
      setPasswordError('Password must be at least 8 characters long');
      return;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      return;
    }

    setIsLoading(true);
    const result = await resetPassword(userEmail, newPassword);
    setIsLoading(false);
    if (result.success) {
      setToastText('Password reset successfully!');
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
        navigate('/');
      }, 3000);
    } else {
      setToastText(result.message);
      setOpenToast(true);
    }
  };

  const handleClose = () => {
    setOpenToast(false);
  };

  const handleCloseDialog = () => {
    setErrorOpen(false);
    navigate('/');
  };

  useEffect(() => {
    const verifyToken = async (token) => {
      try {
        const response = await fetch(
          `${config.backendUrl}/verifyToken?token=${token}`
        );

        if (response.ok) {
          const data = await response.json();
          setUserEmail(data.email);
          console.log('Token is valid:');
        } else {
          const errorData = await response.json();
          console.log(errorData.message);
          setErrorOpen(true);
        }
      } catch (error) {
        console.error('Token verification error:', error);
      }
    };
    verifyToken(token);
  }, [token]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: { xs: '90%', sm: '400px' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
          borderRadius: '16px',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            alignSelf: 'flex-start',
            fontWeight: 'bold',
            color: '#003D78',
            mb: 2,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: '-2px',
              left: 0,
              width: '60px',
              height: '3px',
              backgroundColor: '#2CC4EF',
            },
          }}
        >
          Reset Password
        </Typography>

        {/* New Password Field */}
        <TextField
          size="small"
          fullWidth
          label="New Password"
          type={showNewPassword ? 'text' : 'password'}
          variant="standard"
          margin="normal"
          InputLabelProps={{
            sx: {
              color: '#003D78',
              '&.Mui-focused': {
                color: 'primary.main',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon sx={{ color: '#003D78' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  edge="end"
                  sx={{ color: '#003D78' }}
                >
                  {showNewPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              padding: '2px',
              '&:before': {
                borderBottomColor: '#003D78',
              },
              '&:after': {
                borderBottomColor: 'primary.main',
              },
            },
          }}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError}
        />

        {/* Confirm Password Field */}
        <TextField
          size="small"
          fullWidth
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          variant="standard"
          margin="normal"
          InputLabelProps={{
            sx: {
              color: '#003D78',
              '&.Mui-focused': {
                color: 'primary.main',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon sx={{ color: '#003D78' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                  sx={{ color: '#003D78' }}
                >
                  {showConfirmPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              padding: '2px',
              '&:before': {
                borderBottomColor: '#003D78',
              },
              '&:after': {
                borderBottomColor: 'primary.main',
              },
            },
          }}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
        />

        {/* Reset Button */}
        <Button
          size="large"
          fullWidth
          sx={{
            fontWeight: 'bold',
            borderRadius: '8px',
            backgroundColor: '#003D78',
            marginTop: '0.5rem',
            textTransform: 'none',
            '&:hover': { backgroundColor: 'primary.main', color: '#fff' },
            transition: 'background-color 0.3s ease-in-out',
          }}
          variant="contained"
          onClick={handleReset}
        >
          {isLoading ? (
            <CircularProgress size={22} sx={{ color: '#fff' }} />
          ) : (
            'Reset Password'
          )}
        </Button>
      </Box>

      {/* Snackbar for Success/Error Messages */}
      {openToast && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openToast}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            severity={
              toastText === 'Password reset successfully!' ? 'success' : 'error'
            }
            variant="filled"
            sx={{ width: '100%' }}
            onClose={handleClose}
          >
            {toastText}
          </Alert>
        </Snackbar>
      )}

      <Dialog
        open={errorOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '20px',
            width: { xs: '90%', sm: '400px' },
            height: 'auto',
            maxWidth: '100%',
          },
        }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up', timeout: 500 }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
          }}
        >
          <ErrorIcon sx={{ color: '#dc3545', fontSize: '50px' }} />
          <DialogTitle
            sx={{
              color: '#003D78',
              fontWeight: 'bold',
              fontSize: '20px',
              padding: '12px 0',
            }}
          >
            Invalid or Expired Token
          </DialogTitle>
          <Typography
            variant="body1"
            gutterBottom
            textAlign="center"
            sx={{
              fontSize: '16px',
              marginTop: '8px',
              color: '#333',
              padding: '0 16px',
            }}
          >
            Your reset link has expired or is invalid. Please request a new link
            to reset your password.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'center', padding: '16px 0' }}
        >
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            sx={{
              bgcolor: '#003D78',
              textTransform: 'none',
              padding: '10px 24px',
              borderRadius: '8px',
              '&:hover': {
                bgcolor: '#002F60',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ResetPasswordPage;
