import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';

const DeleteDialog = ({ open, onClose, onDelete, chatTitle }) => {
  const handleDelete = (event) => {
    onDelete(event, chatTitle.chatId);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '14px',
            background: '#fff',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        {/* Dialog Title */}
        <DialogTitle
          id="delete-confirmation-dialog"
          sx={{
            fontSize: '18px',
            fontWeight: 'bold',
            borderBottom: '1px solid #e0e0e0',
            paddingBottom: '16px',
          }}
        >
          Delete Chat?
        </DialogTitle>

        {/* Dialog Content */}
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ marginBottom: 1 }}
          >
            This will delete <strong>{chatTitle?.title}</strong>.
          </Typography>
          <Typography variant="body2" color="textSecondary" textAlign="center">
            You won’t be able to recover this chat once deleted.
          </Typography>
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions
          sx={{
            justifyContent: 'flex-end',
            marginTop: 2,
          }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            sx={{
              borderRadius: '24px',
              padding: '8px 24px',
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => handleDelete(e)}
            color="error"
            variant="contained"
            sx={{
              borderRadius: '24px',
              padding: '8px 24px',
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDialog;
