import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  Typography,
  Box,
  Stack,
  Button,
  FormControl,
  TextField,
  Autocomplete,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import {
  applyStatuteFilters,
  statuteSearchResults,
} from '../../actions/statuteSearchActions';

const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const StatuteSearchSidebar = ({
  selectedLetter,
  updateSelectedLetter,
  resultsCount,
  currentPage,
  updateCurrentPage,
  searchMode,
  statuteSearchValue,
}) => {
  const dispatch = useDispatch();

  // const statutePagination = useSelector((state) => state.statutePagination);
  // const { pageNumber, resultsCount } = statutePagination;

  const [selectedYear, setSelectedYear] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [typeOptions, setTypeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [yearError, setYearError] = useState('');

  const hasFilters = selectedLetter !== '' || selectedYear !== '';

  // Debounced API call
  const debouncedApiCall = debounce((value) => {
    // Trigger API request only when year is valid
    dispatch(
      applyStatuteFilters(statuteSearchValue, {
        year: value,
        letter: selectedLetter,
      })
    );
  }, 1000);

  const handleYearChange = (event) => {
    const value = event.target.value;

    // Check if input is numeric
    if (/^\d*$/.test(value)) {
      setSelectedYear(value);
      setYearError('');

      // Check if the input has 4 digits
      if (value.length === 4) {
        debouncedApiCall(value);
        setIsFilterApplied(true);
      } else if (value.length < 4 && value !== '') {
        setYearError('Year must be exactly 4 digits');
      }
    } else {
      setYearError('Please enter a valid numeric year');
    }

    if (!value && isFilterApplied) {
      dispatch(applyStatuteFilters('', { year: '', letter: selectedLetter }));
    }
  };

  const applyFilters = () => {
    dispatch({ type: 'SET_IS_STATUTE_SEARCH_FILTER_APPLIED', payload: true });
    setIsFilterApplied(true);
    updateCurrentPage(1);
    dispatch(
      applyStatuteFilters(
        statuteSearchValue,
        { year: selectedYear, letter: selectedLetter },
        resultsCount,
        (currentPage - 1) * resultsCount
      )
    );
  };

  const handleLetterChange = (value) => {
    if (value === null) {
      updateSelectedLetter('');
    } else {
      updateSelectedLetter(value);
      dispatch({ type: 'SET_IS_STATUTE_SEARCH_FILTER_APPLIED', payload: true });
      setIsFilterApplied(true);
      updateCurrentPage(1);
      dispatch(
        applyStatuteFilters(
          statuteSearchValue,
          { year: selectedYear, letter: value },
          resultsCount,
          (currentPage - 1) * resultsCount
        )
      );
    }
  };

  const handleClearFilters = () => {
    updateSelectedLetter('');
    setSelectedYear('');
    setIsFilterApplied(false);
    updateCurrentPage(1);
    dispatch({ type: 'SET_IS_STATUTE_SEARCH_FILTER_APPLIED', payload: false });
    dispatch(
      statuteSearchResults(
        statuteSearchValue,
        searchMode,
        resultsCount,
        (currentPage - 1) * resultsCount
      )
    );
  };

  useEffect(() => {
    if (isFilterApplied && currentPage !== 1) {
      dispatch(
        applyStatuteFilters(
          statuteSearchValue,
          { year: selectedYear, letter: selectedLetter },
          resultsCount,
          (currentPage - 1) * resultsCount
        )
      );
    }
  }, [isFilterApplied, resultsCount, currentPage]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await axios.get(
          'https://rg-law-gpt-be-auth2.azurewebsites.net/api/getStatuteFields'
        );
        const { statuteTypes = [], categories = [] } = response.data;

        // Helper function to format text: capitalize first letter and lowercase the rest
        const formatText = (text) => {
          return text
            ? text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter
            : '';
        };

        // Function to remove duplicates, normalize, and sort
        const processOptions = (options) => {
          const uniqueOptions = Array.from(new Set(options)); // Remove duplicates
          return uniqueOptions
            .filter((option) => option && option.trim() !== '') // Remove empty or whitespace values
            .map(formatText) // Format the text
            .sort(); // Sort alphabetically
        };

        // Process statuteTypes and categories
        const formattedStatuteTypes = processOptions(statuteTypes);
        const formattedCategories = processOptions(categories);

        // Set options to state
        setTypeOptions(formattedStatuteTypes.map((label) => ({ label })));
        setCategoryOptions(formattedCategories.map((label) => ({ label })));
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
    };

    fetchFilters();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        backgroundColor: '#f5f5f5',
        flex: 1,
        minWidth: '278px',
        height: 'calc(100vh - 50px)',
        p: '12px 8px 0 12px',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#fff',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#bbb',
          borderRadius: '6px',
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          bgcolor: '#fff',
          p: '8px 5px 8px 8px',
          borderRadius: '8px',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            mb: '18px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid #e6e6e6',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
            }}
          >
            Filters
          </Typography>
          {/* <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
          <Button
            variant="contained"
            size="small"
            startIcon={<FilterAltIcon />}
            sx={{
              mb: '8px',
              textTransform: 'none',
              backgroundColor: hasFilters ? '#00509e' : '#336ba3',
              '&:hover': {
                backgroundColor: hasFilters ? '#003366' : '#336ba3',
              },
              '&.Mui-disabled': {
                backgroundColor: '#336ba3',
                color: '#fff',
              },
            }}
            onClick={applyFilters}
            disabled={!hasFilters}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            startIcon={<CancelIcon />}
            sx={{
              mb: '8px',
              textTransform: 'none',
              '&.Mui-disabled': {
                backgroundColor: '#d65f5f',
                color: '#ffffff',
              },
            }}
            onClick={handleClearFilters}
            disabled={!hasFilters}
          >
            Clear
          </Button>
        </Stack> */}
        </Box>
        {((selectedLetter && isFilterApplied) ||
          (selectedYear && isFilterApplied)) && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              color="error"
              sx={{ textTransform: 'none' }}
              onClick={handleClearFilters}
            >
              Clear filter(s)
            </Button>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 600,
              color: '#333',
              ml: '5px',
            }}
          >
            Letter
          </Typography>
          <FormControl fullWidth sx={{ mt: '4px', mb: '6px' }}>
            <Autocomplete
              size="small"
              value={selectedLetter || ''}
              onChange={(event, newValue) => {
                handleLetterChange(newValue);
              }}
              onInputChange={(e, newValue) => {
                if (!newValue && isFilterApplied) {
                  dispatch(
                    applyStatuteFilters('', { year: selectedYear, letter: '' })
                  );
                }
              }}
              disableClearable={!selectedLetter}
              clearIcon={selectedLetter ? <ClearIcon fontSize="small" /> : null}
              options={alphabets}
              getOptionLabel={(option) => option || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Letter"
                  sx={{
                    width: '95%',
                    ml: '5px',
                    mb: '16px',
                    '& .MuiInputBase-input': {
                      fontSize: '13px',
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '12px',
                    },
                  }}
                />
              )}
              componentsProps={{
                paper: {
                  sx: {
                    '& .MuiAutocomplete-listbox': {
                      fontSize: '13px',
                      height: '180px',
                    },
                  },
                },
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 600,
              color: '#333',
              mb: '4px',
              ml: '5px',
            }}
          >
            Year
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Year"
              variant="outlined"
              value={selectedYear}
              error={!!yearError}
              helperText={yearError ? yearError : ''}
              onChange={(e) => handleYearChange(e)}
              sx={{
                width: '95%',
                ml: '5px',
                mb: '12px',
                '& .MuiInputBase-input': {
                  fontSize: '13px',
                },
                '& .MuiInputBase-input::placeholder': {
                  fontSize: '12px',
                },
              }}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default StatuteSearchSidebar;
