import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import { userLoginReducer, userSignUpReducer } from './reducers/userReducers';
import {
  chatHistoryReducer,
  chatTitlesReducer,
  checkServerReducer,
  newChatReducer,
} from './reducers/chatReducers';
import {
  deletePetitionReducer,
  editPetitionTitleReducer,
  newPetitionReducer,
  petitionHistoryReducer,
  petitionTitlesReducer,
} from './reducers/petitionReducers';
import {
  caseDetailReducer,
  caseDetailsFromCosmosReducer,
  caseFiltersReducer,
  searchFacetsReducer,
  searchFilterPaginationReducer,
  searchFilterValuesReducer,
  searchResultsCountReducer,
  searchResultsReducer,
  searchSelectedFiltersReducer,
} from './reducers/searchReducers';
import statuteSearchReducer, {
  statutePaginationReducer,
  statutePdfPreviewReducer,
  statuteSearchFilterReducer,
  statuteSectionsReducer,
} from './reducers/statuteSearchReducer';
import { statuteTitleReducer } from './reducers/statuteSearchReducer';
import {
  applicationTitlesReducer,
  deleteApplicationReducer,
} from './reducers/applicationReducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['chatTitles'], // Only persist chatTitlesReducer
}; 

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userSignup: userSignUpReducer,
  chatTitles: chatTitlesReducer,
  petitionTitles: petitionTitlesReducer,
  newChat: newChatReducer,
  newPetition: newPetitionReducer,
  chatHistory: chatHistoryReducer,
  petitionHistory: petitionHistoryReducer,
  editPetitionTitle: editPetitionTitleReducer,
  deletePetition: deletePetitionReducer,
  serverStatus: checkServerReducer,
  searchResultsCount: searchResultsCountReducer,
  searchResults: searchResultsReducer,
  searchFacets: searchFacetsReducer,
  searchFilters: searchFilterValuesReducer,
  searchFiltersPagination: searchFilterPaginationReducer,
  selectedFilters: searchSelectedFiltersReducer,
  caseDetail: caseDetailReducer,
  caseDetailsFromCosmos: caseDetailsFromCosmosReducer,
  caseFilters: caseFiltersReducer,
  statutePagination: statutePaginationReducer,
  statuteSearch: statuteSearchReducer,
  isStatuteFilterApplied: statuteSearchFilterReducer,
  statuteSections: statuteSectionsReducer,
  statuteTitle: statuteTitleReducer,
  statutePdfPreviewUrl: statutePdfPreviewReducer,
  applicationTitles: applicationTitlesReducer,
  deleteApplication: deleteApplicationReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);


const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const preloadedState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const store = configureStore({
  reducer : persistedReducer,
  middleware,
  preloadedState,
});

const persistor = persistStore(store);

export { store, persistor };

