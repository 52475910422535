import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Box, Stack, Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Sidebar from './components/layout/Sidebar';
import ChatInterface from './components/ChatInterface';
import { initializeGA, trackPageView } from './analytics';
import NotFound from './components/NotFound';
import PetitionSidebar from './components/layout/PetitionSidebar';
import PetitionForm from './components/PetitionForm';
import LegalSearchSidebar from './components/layout/LegalSearchSidebar';
import SearchResults from './components/SearchResults';
import CaseDetail from './components/CaseDetail';
import CaseDetailRightSidebar from './components/layout/CaseDetailRightSidebar';
import HomeSidebar from './components/layout/HomeSidebar';
import StatuteSearch from './components/StatuteSearch';
import Bookmarks from './components/Bookmarks';
import AppForm from './components/AppForm';
import ApplicationSidebar from './components/layout/ApplicationSidebar';
import LegalDocumentSidebar from './components/layout/LegalDocumentSidebar';
import DocumentAnalysis from './components/DocumentAnalysis';
import StatuteSearchSidebar from './components/layout/StatuteSearchSidebar';
import Header from './components/layout/Header';
import LegalLibrary from './components/LegalLibrary';
import CaseSummarySidebar from './components/layout/CaseSummarySidebar';
import CaseSummary from './components/CaseSummary';
import StatuteDetail from './components/StatuteDetail';
import StatuteDetailSidebar from './components/layout/StatuteDetailSidebar';
import StatutePdfViewer from './components/StatutePdfViewer';

const queryClient = new QueryClient();
initializeGA();

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const location = useLocation();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [statuteSearchValue, setStatuteSearchValue] = useState('');
  const [selectedStatuteLetterFilter, setSelectedStatuteLetterFilter] =
    useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsCount, setResultsCount] = useState(10);
  const [searchMode, setSearchMode] = useState('keyword');
  const [caseLawCurrentPage, setCaseLawCurrentPage] = useState(1);
  const [isNewCaseLawSearch, setIsNewCaseLawSearch] = useState(false);
  const [caseLawSearchMode, setCaseLawSearchMode] = useState('keyword');
  const [caseLawSortBy, setCaseLawSortBy] = useState('Most Relevant');
  const [caseLawResultsCount, setCaseLawResultsCount] = useState(10);

  const [selectedStatuteSection, setSelectedStatuteSection] =
    useState('PREAMBLE. Preamble');

  const handleStatuteSectionClick = (section) => {
    setSelectedStatuteSection(section);
  };

  const updateResultsCount = (value) => {
    setResultsCount(value);
  };

  const updateSearchMode = (value) => {
    setSearchMode(value);
  };

  const updateSelectedStatuteLetterFilter = (letter) => {
    setSelectedStatuteLetterFilter(letter);
  };

  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const [selectedPetitionTab, setSelectedPetitionTab] = useState(0);

  const handlePetitionTabClick = (value) => {
    setSelectedPetitionTab(value);
  };

  const [selectedApplicationTab, setSelectedApplicationTab] = useState(0);
  const handleApplicationTabClick = (value) => {
    setSelectedApplicationTab(value);
  };

  const [selectedAnalysisTab, setSelectedAnalysisTab] = useState(0);
  const handleAnalysisTabClick = (value) => {
    setSelectedAnalysisTab(value);
  };

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return userInfo ? (
    <QueryClientProvider client={queryClient}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        }}
      >
        <Header />
        <Stack direction="row" height="100%">
          {isSidebarVisible && location.pathname === '/app/legal-chat' && (
            <Drawer
              anchor="left"
              open={isSidebarVisible}
              sx={{
                '& .MuiDrawer-paper': {
                  width: '80%',
                },
              }}
              ModalProps={{
                keepMounted: true,
              }}
              onClose={toggleSidebarVisibility}
            >
              <Sidebar isVisible={isSidebarVisible} />
            </Drawer>
          )}
          <HomeSidebar />
          {location.pathname === '/app/legal-chat' && <Sidebar />}
          {location.pathname === '/app/petition' && (
            <PetitionSidebar
              onPetitionTabClick={handlePetitionTabClick}
              selectedTab={selectedPetitionTab}
            />
          )}
          {location.pathname === '/app/app-management' && (
            <ApplicationSidebar
              onApplicationTabClick={handleApplicationTabClick}
            />
          )}
          {location.pathname === '/app/document-analysis' && (
            <LegalDocumentSidebar onAnalysisTabClick={handleAnalysisTabClick} />
          )}
          {location.pathname === '/app/searchResults' && (
            <LegalSearchSidebar
              searchKeyword={searchKeyword}
              currentPage={caseLawCurrentPage}
              setCurrentPage={setCaseLawCurrentPage}
              isNewCaseLawSearch={isNewCaseLawSearch}
              setIsNewCaseLawSearch={setIsNewCaseLawSearch}
              searchMode={caseLawSearchMode}
              sortBy={caseLawSortBy}
              resultsCount={caseLawResultsCount}
            />
          )}
          {/* {location.pathname === '/app/case-summary' && (
            <CaseSummarySidebar
              onSectionClick={handleSummarySectionClick}
              selectedSection={selectedSummarySection}
            />
          )} */}
          {location.pathname === '/app/statute-detail' && (
            <StatuteDetailSidebar
              onSectionClick={handleStatuteSectionClick}
              selectedSection={selectedStatuteSection}
            />
          )}
          {location.pathname === '/app/legal-chat' && (
            <ChatInterface toggleSidebar={toggleSidebarVisibility} />
          )}
          {location.pathname === '/app/case-management' && <NotFound />}
          {location.pathname === '/app/petition' && (
            <PetitionForm
              selectedPetitionTab={selectedPetitionTab}
              onPetitionTabClick={handlePetitionTabClick}
            />
          )}
          {location.pathname === '/app/app-management' && (
            <AppForm
              selectedApplicationTab={selectedApplicationTab}
              onApplicationTabClick={handleApplicationTabClick}
            />
          )}
          {location.pathname === '/app/document-analysis' && (
            <DocumentAnalysis selectedAnalysisTab={selectedAnalysisTab} />
          )}
          {location.pathname === '/app/legal-library' && <LegalLibrary />}
          {location.pathname === '/app/bookmarks' && <Bookmarks />}
          {location.pathname === '/app/searchResults' && (
            <SearchResults
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              currentPage={caseLawCurrentPage}
              setCurrentPage={setCaseLawCurrentPage}
              setIsNewCaseLawSearch={setIsNewCaseLawSearch}
              searchMode={caseLawSearchMode}
              updateSearchMode={setCaseLawSearchMode}
              sortBy={caseLawSortBy}
              updateSortBy={setCaseLawSortBy}
              resultsCount={caseLawResultsCount}
              updateResultsCount={setCaseLawResultsCount}
            />
          )}
          {location.pathname === '/app/statute-search' && (
            <StatuteSearchSidebar
              selectedLetter={selectedStatuteLetterFilter}
              updateSelectedLetter={updateSelectedStatuteLetterFilter}
              resultsCount={resultsCount}
              currentPage={currentPage}
              updateCurrentPage={setCurrentPage}
              searchMode={searchMode}
              statuteSearchValue={statuteSearchValue}
            />
          )}
          {location.pathname === '/app/statute-search' && (
            <StatuteSearch
              searchMode={searchMode}
              updateSearchMode={updateSearchMode}
              resultsCount={resultsCount}
              updateResultsCount={updateResultsCount}
              currentPage={currentPage}
              updateCurrentPage={setCurrentPage}
              statuteSearchValue={statuteSearchValue}
              updateStatuteSearchValue={setStatuteSearchValue}
            />
          )}
          {location.pathname === '/app/statute-detail' && (
            <StatuteDetail selectedSection={selectedStatuteSection} />
          )}
          {location.pathname === '/app/statute-preview' && <StatutePdfViewer />}
          {/* {location.pathname === '/app/case-summary' && (
            <CaseSummary selectedSection={selectedSummarySection} />
          )} */}
          {/* {location.pathname === '/app/case-detail' && <CaseDetail />} */}
          {/* {location.pathname === '/app/case-detail' && (
            <CaseDetailRightSidebar casebotContext={casebotContext} />
          )} */}
        </Stack>
      </Box>
    </QueryClientProvider>
  ) : (
    <Navigate to="/" />
  );
}

export default App;
