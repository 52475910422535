import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  CircularProgress,
  Modal,
  Tooltip,
  Snackbar,
  Alert,
  Paper,
} from '@mui/material';
import { Delete, Remove, SwapHoriz, SwapVert } from '@mui/icons-material';
import BookmarkSidebar from './UI/BookmarkSidebar';
import { useSelector, useDispatch } from 'react-redux';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import PreviewIcon from '@mui/icons-material/Preview';
import { useNavigate } from 'react-router-dom';
import CaseLawPreviewModal from './UI/CaseLawPreviewModal';
import StatutePreviewModal from './UI/StautePreviewModal';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { blue, green, red, orange, grey } from '@mui/material/colors';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoadingModal from './UI/LoadingModal';
import CancelIcon from '@mui/icons-material/Cancel';

const azureSearchUrl =
  'https://rg-law-gpt-ai-search-v3.search.windows.net//indexes/case_laws_indexing_v5/docs/search?api-version=2024-05-01-preview';
const functionAppUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/getUserBookmarkGroups';
const deleteCaseUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteCaseOrStatuteFromGroup';
const azureStatuteSearch =
  'https://rg-law-gpt-ai-search-v3.search.windows.net/indexes/statutes-index-v5-suggestions-and-autocomplete/docs/search?api-version=2024-05-01-preview';
const moveCaseUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/moveCaseOrStatuteBetweenGroups';

const Bookmarks = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [cases, setCases] = useState([]);
  const [statutes, setStatutes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [selectedStatute, setSelectedStatute] = useState(null);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [targetGroupId, setTargetGroupId] = useState('');
  const [editNoteItem, setEditNoteItem] = useState(null);
  const [newNote, setNewNote] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // State to keep track of the sort order
  const [loadingCases, setLoadingCases] = useState(false);
  const [loadingStatutes, setLoadingStatutes] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [inputError, setInputError] = useState('');

  const handlePreviewClick = (item) => {
    if (item.type === 'case') {
      setSelectedCase(item);
      setOpenPreview(true);
    } else if (item.type === 'statute') {
      setSelectedStatute(item);
      setOpenPreview(true);
    }
  };

  const handleNoteChange = (e) => {
    const value = e.target.value;
    if (value.length <= 2000) {
      setNewNote(value);
      setInputError('');
    } else {
      setInputError('You can only input 2000 characters');
    }
  };

  const handleNoteClick = (item) => {
    handleEditNote(item);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setSelectedCase(null);
    setSelectedStatute(null);
  };

  const handleOpenConfirmDialog = (itemId, groupId, itemType) => {
    setItemToDelete({ itemId, groupId, itemType });
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setItemToDelete(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchGroups = async () => {
    setLoadingGroups(true);
    try {
      const response = await fetch(`${functionAppUrl}?userId=${userInfo._id}`);
      const data = await response.json();
      setAvailableGroups(data);
    } catch (error) {
      console.error('Error fetching groups:', error);
    } finally {
      setLoadingGroups(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, [userInfo._id]);

  useEffect(() => {
    if (selectedGroup) {
      setStatutes([]);
      setCases([]);
      fetchCases(selectedGroup);
      fetchStatutes(selectedGroup);
      setLoadingStatutes(true);
    }
  }, [selectedGroup]);

  const fetchCases = async (groupId) => {
    try {
      const response = await fetch(`${functionAppUrl}?userId=${userInfo._id}`);
      const data = await response.json();
      const selectedGroupData = data.find((group) => group._id === groupId);

      if (selectedGroupData) {
        const caseIds = selectedGroupData.cases
          .map((caseItem) => `id eq '${caseItem.id}'`)
          .join(' or ');

        if (caseIds) {
          const searchResponse = await fetch(azureSearchUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'api-key': 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv',
            },
            body: JSON.stringify({
              filter: caseIds,
            }),
          });
          const searchData = await searchResponse.json();
          const casesWithDates = searchData.value.map((caseItem) => {
            const matchedCase = selectedGroupData.cases.find(
              (item) => item.id === caseItem.id
            );
            return {
              ...caseItem,
              addedAt: matchedCase ? matchedCase.addedAt : null,
              note: matchedCase ? matchedCase.note : '',
              type: 'case',
            };
          });
          setCases(casesWithDates);
        } else {
          setCases([]);
        }
      } else {
        setCases([]);
      }
    } catch (error) {
      console.error('Error fetching cases:', error);
    } finally {
      setLoadingCases(false);
    }
  };

  const fetchStatutes = async (groupId) => {
    try {
      const response = await fetch(`${functionAppUrl}?userId=${userInfo._id}`);
      const data = await response.json();

      const selectedGroupData = data.find((group) => group._id === groupId);

      if (selectedGroupData && selectedGroupData.statutes.length > 0) {
        const statutesIds = selectedGroupData.statutes
          .map((statutesItem) => `"${statutesItem.id}"`)
          .join(' or ');

        if (statutesIds) {
          const searchResponse = await fetch(azureStatuteSearch, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'api-key': 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv',
            },
            body: JSON.stringify({
              search: statutesIds,
              searchFields: 'id',
            }),
          });

          if (!searchResponse.ok) {
            throw new Error('Failed to fetch statutes from Azure Search.');
          }

          const searchData = await searchResponse.json();

          const statutesWithDates = searchData.value.map((statutesItem) => {
            const matchedStatutes = selectedGroupData.statutes.find(
              (item) => item.id === statutesItem.id
            );

            return {
              ...statutesItem,
              addedAt: matchedStatutes ? matchedStatutes.addedAt : null,
              note: matchedStatutes ? matchedStatutes.note : '',
              type: 'statute',
            };
          });

          setStatutes(statutesWithDates);
        } else {
          setStatutes([]);
        }
      } else {
        setStatutes([]);
      }
    } catch (error) {
      console.error('Error fetching statutes:', error);
    } finally {
      setLoadingStatutes(false);
    }
  };

  const handleGroupSelect = (groupId) => {
    setSelectedGroup(groupId);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = async (itemId, groupId, itemType) => {
    try {
      const response = await fetch(deleteCaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupId,
          caseId: itemType === 'case' ? itemId : undefined,
          statuteId: itemType === 'statute' ? itemId : undefined,
        }),
      });

      if (response.ok) {
        if (itemType === 'case') {
          setCases(cases.filter((caseItem) => caseItem.id !== itemId));
        } else if (itemType === 'statute') {
          setStatutes(
            statutes.filter((statutesItem) => statutesItem.id !== itemId)
          );
        }
        setSnackbarMessage({
          type: 'success',
          text: 'Bookmark Deleted!',
        });
      } else {
        console.error('Error deleting item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleMove = (item) => {
    fetchGroups();
    setSelectedItem(item);
    setMoveModalOpen(true);
  };

  const handleMoveConfirm = async () => {
    if (!targetGroupId) return;

    try {
      const response = await fetch(moveCaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo._id,
          fromGroupId: selectedGroup,
          toGroupId: targetGroupId,
          caseId: selectedItem.type === 'case' ? selectedItem.id : undefined,
          statuteId:
            selectedItem.type === 'statute' ? selectedItem.id : undefined,
        }),
      });

      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Bookmark Copied!',
        });
        // Remove the moved item from the current group
        // if (selectedItem.type === "case") {
        //   setCases(cases.filter((caseItem) => caseItem.id !== selectedItem.id));
        // } else if (selectedItem.type === "statute") {
        //   setStatutes(
        //     statutes.filter(
        //       (statutesItem) => statutesItem.id !== selectedItem.id
        //     )
        //   );
        // }

        setMoveModalOpen(false);
        setSelectedItem(null);
        setTargetGroupId('');
      } else {
        console.error('Error moving item');
      }
    } catch (error) {
      console.error('Error moving item:', error);
    }
  };

  const handleUpdateNote = async () => {
    if (!editNoteItem || !newNote) return;

    try {
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/updateNote',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userInfo._id,
            groupId: selectedGroup,
            itemId: editNoteItem.id || editNoteItem.id,
            itemType: editNoteItem.type,
            note: newNote,
          }),
        }
      );

      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Notes Updated!',
        });

        if (editNoteItem.type === 'case') {
          setCases(
            cases.map((caseItem) =>
              caseItem.id === editNoteItem.id
                ? { ...caseItem, note: newNote }
                : caseItem
            )
          );
        } else if (editNoteItem.type === 'statute') {
          setStatutes(
            statutes.map((statutesItem) =>
              statutesItem.id === editNoteItem.id
                ? { ...statutesItem, note: newNote }
                : statutesItem
            )
          );
        }

        setEditNoteItem(null);
        setNewNote('');
      } else {
        console.error('Error updating note');
      }
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const handleRemoveNoteClick = async (item) => {
    try {
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/removeNote',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userInfo._id,
            groupId: selectedGroup,
            itemId: item.id || item.id,

            itemType: item.type,
          }),
        }
      );

      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Notes Removed!',
        });

        if (item.type === 'case') {
          setCases(
            cases.map((caseItem) =>
              caseItem.id === item.id ? { ...caseItem, note: '' } : caseItem
            )
          );
        } else if (item.type === 'statute') {
          setStatutes(
            statutes.map((statutesItem) =>
              statutesItem.id === item.id
                ? { ...statutesItem, note: '' }
                : statutesItem
            )
          );
        }
        setEditNoteItem(null);
      } else {
        console.error('Error removing note');
      }
    } catch (error) {
      console.error('Error removing note:', error);
    }
  };

  const handleEditNote = (item) => {
    setEditNoteItem(item);
    setNewNote(item.note || '');
  };

  const handleMoveCancel = () => {
    setMoveModalOpen(false);
    setSelectedItem(null);
    setTargetGroupId('');
  };

  const formatCaseTitle = (title) => {
    const splitTitle = title.split('Honorable')[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return titleCaseTitle;
  };

  const combinedItems = [...cases, ...statutes]
    .filter((item) => {
      if (item.type === 'case') {
        return item.Title.toLowerCase().includes(searchTerm.toLowerCase());
      } else if (item.type === 'statute') {
        return item.Statute_Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        );
      }
      return false;
    })
    .sort((a, b) => new Date(b.addedAt) - new Date(a.addedAt));

  const handleSort = (column) => {
    const sortOrderValue = sortOrder === 'asc' ? 1 : -1;

    const sortedData = [...cases].sort((a, b) => {
      let valueA, valueB;

      if (column === 'title') {
        valueA = a.title.toLowerCase();
        valueB = b.title.toLowerCase();
      } else if (column === 'date') {
        valueA = new Date(a.addedAt);
        valueB = new Date(b.addedAt);
      }

      if (valueA < valueB) {
        return -1 * sortOrderValue;
      }
      if (valueA > valueB) {
        return 1 * sortOrderValue;
      }
      return 0;
    });

    setCases(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortedItems = [...cases, ...statutes]
    .filter((item) => {
      if (item.type === 'case') {
        return item.Title.toLowerCase().includes(searchTerm.toLowerCase());
      } else if (item.type === 'statute') {
        return item.Statute_Name.toLowerCase().includes(
          searchTerm.toLowerCase()
        );
      }
      return false;
    })
    .sort((a, b) => {
      if (sortOrder === 'asc') {
        return (
          a.Title?.localeCompare(b.Title) ||
          a.Statute_Name?.localeCompare(b.Statute_Name)
        );
      } else {
        return (
          b.Title?.localeCompare(a.Title) ||
          b.Statute_Name?.localeCompare(a.Statute_Name)
        );
      }
    });

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <Box
      sx={{
        flex: 5,
        display: 'flex',
        flexDirection: 'row',
        bgcolor: '#f5f5f5',
        height: 'calc(100vh - 50px)',
      }}
    >
      <BookmarkSidebar onGroupSelect={handleGroupSelect} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          backgroundColor: '#fff',
          flex: 5,
          p: 3,
          m: '12px 12px 0 12px',
          borderRadius: '10px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
          overflow: 'auto',
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: '#fff',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#bbb',
            borderRadius: '6px',
          },
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          sx={{
            width: '100%',
            maxWidth: '400px',
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
              backgroundColor: '#f8f8f8',
              transition: 'all 0.3s',
              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
              '&.Mui-focused': {
                backgroundColor: '#ffffff',
                boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
              },
            },
          }}
          placeholder="Search Items"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: grey[500], mr: 1 }} />,
            sx: {
              fontSize: '14px',
              borderRadius: '30px',
            },
          }}
        />
        <LoadingModal open={loadingCases || loadingStatutes} />
        <>
          {combinedItems.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  fontSize: '50px',
                  marginBottom: '10px',
                  color: '#303f9f',
                }}
              >
                <SearchIcon fontSize="inherit" />
              </Box>
              <Typography
                variant="h5"
                sx={{ fontSize: '24px', marginBottom: '10px' }}
              >
                No Bookmarks Found
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: '16px', color: '#666' }}
              >
                Start organizing your key legal references for quick and easy
                access
              </Typography>
            </Box>
          ) : (
            <>
              <Typography
                variant="subtitle1"
                sx={{ mb: 1, color: '#101936', fontWeight: 600 }}
              >
                Bookmark Items
              </Typography>
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: 'none',
                  borderRadius: '12px',
                  overflow: 'hidden',
                }}
              >
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                      <TableCell
                        sx={{ fontWeight: 600, color: '#101936', py: 2 }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          Title
                          <IconButton
                            onClick={() => handleSort('title')}
                            size="small"
                            sx={{ ml: 1 }}
                          >
                            <SwapVert fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 600, color: '#101936', py: 2 }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 600, color: '#101936', py: 2 }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          Date
                          <IconButton
                            onClick={() => handleSort('date')}
                            size="small"
                            sx={{ ml: 1 }}
                          >
                            <SwapVert fontSize="small" />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 600, color: '#101936', py: 2 }}
                      >
                        Notes
                      </TableCell>
                      <TableCell
                        sx={{ fontWeight: 600, color: '#101936', py: 2 }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedItems.map((item, index) => (
                      <TableRow
                        key={item.id}
                        sx={{
                          backgroundColor: 'white',
                          transition: 'all 0.3s',
                          '&:hover': {
                            backgroundColor: '#f9f9f9',
                            transform: 'translateY(-2px)',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
                          },
                          '&:not(:last-child)': {
                            borderBottom: '1px solid #f0f0f0',
                          },
                        }}
                      >
                        <TableCell
                          sx={{ py: 2, color: '#101936', fontWeight: 500 }}
                        >
                          {item.type === 'case'
                            ? truncateText(formatCaseTitle(item.Title), 100)
                            : truncateText(
                                formatCaseTitle(item.Statute_Name),
                                100
                              )}
                        </TableCell>
                        <TableCell sx={{ py: 2, color: grey[700] }}>
                          {item.type === 'case' ? 'Case' : 'Statute'}
                        </TableCell>
                        <TableCell sx={{ py: 2, color: grey[700] }}>
                          {new Date(item.addedAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell
                          sx={{
                            py: 2,
                            maxWidth: '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            cursor: 'pointer',
                            color: blue[700],
                            '&:hover': { textDecoration: 'underline' },
                          }}
                          onClick={() => handleNoteClick(item)}
                        >
                          {item.note || 'No notes available'}
                        </TableCell>
                        <TableCell sx={{ py: 2 }}>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Tooltip title="Preview">
                              <IconButton
                                size="small"
                                sx={{
                                  color: blue[500],
                                  '&:hover': { backgroundColor: blue[50] },
                                }}
                                onClick={() => handlePreviewClick(item)}
                              >
                                <RemoveRedEyeIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                sx={{
                                  color: red[500],
                                  '&:hover': { backgroundColor: red[50] },
                                }}
                                onClick={() =>
                                  handleOpenConfirmDialog(
                                    item.id,
                                    selectedGroup,
                                    item.type
                                  )
                                }
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Read More">
                              <IconButton
                                size="small"
                                sx={{
                                  color: green[500],
                                  '&:hover': { backgroundColor: green[50] },
                                }}
                                onClick={() => {
                                  if (item.type === 'case') {
                                    dispatch({
                                      type: 'CASE_DETAIL_SUCCESS',
                                      payload: item,
                                    });
                                    navigate('/app/case-detail');
                                  } else {
                                    dispatch({
                                      type: 'SET_STATUTE_TITLE',
                                      payload: item.Statute_Name,
                                    });
                                    navigate('/app/statute-search');
                                  }
                                }}
                              >
                                <MenuBookIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Copy Bookmark">
                              <IconButton
                                size="small"
                                sx={{
                                  color: orange[500],
                                  '&:hover': { backgroundColor: orange[50] },
                                }}
                                onClick={() => handleMove(item)}
                              >
                                <SwapHoriz fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
        {snackbarMessage && (
          <Snackbar
            open={!!snackbarMessage}
            autoHideDuration={6000}
            onClose={() => setSnackbarMessage(null)}
          >
            <Alert
              onClose={() => setSnackbarMessage(null)}
              severity={snackbarMessage.type}
              sx={{ width: '100%' }}
            >
              {snackbarMessage.text}
            </Alert>
          </Snackbar>
        )}
      </Box>
      <Dialog open={moveModalOpen} onClose={handleMoveCancel}>
        <DialogTitle>Copy to Another Group</DialogTitle>
        <DialogContent sx={{ width: 400, overflow: 'visible' }}>
          <Select
            value={targetGroupId}
            onChange={(e) => setTargetGroupId(e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a group
            </MenuItem>
            {availableGroups.map((group) => (
              <MenuItem
                sx={{
                  maxWidth: '400px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'block',
                }}
                key={group._id}
                value={group._id}
              >
                {group.groupName}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleMoveCancel} color="error">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleMoveConfirm}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {selectedCase && (
        <CaseLawPreviewModal
          open={openPreview}
          onClose={handleClosePreview}
          title={selectedCase ? formatCaseTitle(selectedCase.Title) : ''}
          caseDescriptionContent={
            selectedCase ? selectedCase.Case_Description_HTML : ''
          }
          headNotesContent={selectedCase ? selectedCase.Head_Notes_HTML : ''}
        />
      )}
      {selectedStatute && (
        <StatutePreviewModal
          open={openPreview}
          onClose={handleClosePreview}
          statute={selectedStatute}
        />
      )}
      <Dialog open={!!editNoteItem} onClose={() => setEditNoteItem(null)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <DialogTitle>Edit Note</DialogTitle>
          <IconButton onClick={() => setEditNoteItem(null)}>
            <CancelIcon color="error" />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            width: 400,
            '::-webkit-scrollbar': {
              width: '5px',
            },
            '::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            '::-webkit-scrollbar-thumb': {
              background: '#101936',
              borderRadius: '6px',
            },
          }}
        >
          {isEditing ? (
            <TextField
              sx={{
                maxHeight: 100,
              }}
              autoFocus
              margin="dense"
              label="Note"
              type="text"
              fullWidth
              error={Boolean(inputError)}
              helperText={inputError}
              value={newNote}
              onChange={handleNoteChange}
              multiline
              onBlur={() => setIsEditing(false)} // Exit editing mode on blur
            />
          ) : (
            <Typography
              variant="body1"
              onClick={() => setIsEditing(true)} // Enter editing mode on click
              sx={{
                cursor: 'pointer',
                whiteSpace: 'pre-wrap', // Preserve line breaks
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {newNote || 'Click to add note...'}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleRemoveNoteClick(editNoteItem)}
            color="error"
          >
            Delete Notes
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateNote}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this bookmark?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ bgcolor: '#303f9f' }}
            variant="contained"
            onClick={() => {
              handleDelete(
                itemToDelete.itemId,
                itemToDelete.groupId,
                itemToDelete.itemType
              );
              handleCloseConfirmDialog(); // Close the dialog after deletion
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            onClick={handleCloseConfirmDialog}
            color="error"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Bookmarks;
